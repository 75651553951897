const SchemesBG = () => {

    return (
        <section id="schemeDetailsSection" className="ryb__details">
            <h5 className="ryb__info">Цветови схеми</h5>

            <article className="ryb__details--text-only"><b><i>Цветовите схеми </i></b> се използват, за да създават стил и да привличат. 
            Цветовете и комбинациите с тях предизвикват психологически ефект, събуждат определени чувства и настроения. 
            Те са широко изолзвано средство от художници, стилисти и дизайнери, от изкуството до маркетинга и рекламата. 
            Основните комбинации съчетават два цвята, които изглеждат привлекателни заедно. 
            По-сложните схеми комбинират няколко цвята, добавяйки контраст и акценти. 
            Цветовите схеми следват логически връзки според подредбата на цветовете в цветното колело. 
            Различните варианти на цветова хармония дават разнообразие в контраста и динамиката, елегантността и въздействието.</article>
            <div className="ryb__details--container">
                <img src="/images/schemes/mono.JPG" alt="monochromatic" className="ryb__details--img" />
                <article className="ryb__details--text-with-img"><b><i>Монохромната цветова схема</i></b> използва 
                един основен цвят и неговите оттенъци - нюанси, сенки и тонове, получени с добавянето на бяло, черно или сиво.
                Лесна е за създаване, с лекота се прилага и възриема. Тази цветова схема излъчва чистота и елегантност. 
                Липсата на контраст я прави фина и деликатна и носи успокояващ ефект. 
                Динамика може да бъде постигната с комбинирането на тъмни сенки и светли нюанси и дори черно и бяло. 
                Изпoлзването на един цвят и неговите вариации придава дързост и драматизъм, но също така постига балансирана и стилна визия.</article>
            </div>
            <div className="ryb__details--container">
                <img src="/images/schemes/analog3.JPG" alt="analogous" className="ryb__details--img" />
                <article className="ryb__details--text-with-img"><b><i>Аналогичната цветова схема</i></b> използва 
                съседни цветове, подредени един до друг в цветното колело. 
                Лесно се създава и има приятно и елегантно излъчване. 
                Един доминиращ цвят, допълнен с останалите, прави това съчетание хармонично и успокояващо. 
                Цветовете не се сблъскват един с друг, подобни комбинации са често и естествено явление в природата. 
                Липсата на контраст намалява жизнеността. Съседните цветове си пасват по-добре, 
                когато всичките са представители или на топлата, или на студената гама.</article>
            </div>
            <div className="ryb__details--container">
                <img src="/images/schemes/complementary.JPG" alt="complementary" className="ryb__details--img" />
                <article className="ryb__details--text-with-img"><b><i>Допълващата цветова схема</i></b> събира
                два противоположни цвята от цветното колело. Това е хармонията, предлагаща най-голям контраст. 
                Привличаща най-силно вниманието. Единият цвят от двойката е доминиращ. Другият го подсилва и подчертава 
                или се използва за акцент. В тази най-динамична комбинация участват едновременно топъл и студен цвят. 
                Според темературата на доминиращия цвят цялостният дизайн изглежда или топъл, или студен. 
                Атрактивна и трудна за балансиране, допълващата схема предлага остра, ярка и силна визия.</article>
            </div>
            <div className="ryb__details--container">
                <img src="/images/schemes/split.JPG" alt="split-complementary" className="ryb__details--img" />
                <article className="ryb__details--text-with-img"><b><i>Разделно допълващата цветова схема</i></b> е вариация
                на допълващата цветова схема. Събира един основен цвят с двата, съседни на срещуположния му в цветното колело. 
                С добавянето на трети акцент в композицията се отнема от напрежението на дръзкия двуполюсен контраст. 
                Намаляват остротата и жизнеността. Противопоставянето запазва равновесието между топли и студени цветове. 
                Разделно допълващата схема е трудна за балансиране, но предлага атрактивност и хармонична визия.</article>
            </div>
            <div className="ryb__details--container">
                <img src="/images/schemes/triadic.JPG" alt="triadic" className="ryb__details--img" />
                <article className="ryb__details--text-with-img"><b><i>Триадичната цветова схема</i></b> съчетава 
                три цвята, които са разположени на еднакво разстояние един от друг в цветното колело. 
                Един основен цвят се балансира от два равнопоставени - акценти. 
                Комбинацията предлага едновременно силен контраст и хармония. 
                Жизнеността се запазва дори при използването на бледи и обезцветени вариации на цветовете. 
                Триъгълната цветова схема се постига лесно и осигурява динамика и богатство на цветовете.</article>
            </div>
            <div className="ryb__details--container">
                <img src="/images/schemes/square.JPG" alt="square" className="ryb__details--img" />
                <article className="ryb__details--text-with-img"><b><i>Тетрадичната цветова схема</i></b> се нарича
                също четириъгълна или двойно доълваща. 
                Четирите цвята са комбинирани като две допълващи се двойки от цветното колело.    
                Това по-сложно съчетание затруднява постигането на баланс и хармония. 
                Един от цветовете следва да бъде доминиращ, а останалите могат да участват 
                с пастелните си или обезцветени оттенъци. 
                Двойките образуват <b><i>правоъгълна схема</i></b> или <b><i>квадратна схема</i></b>, ако 
                подбраните цветове отстоят на равни разстояния един от друг. 
                Тетрадичната е най-богата цветова схема и дава възможност за широк избор и разнообразни комбинации.</article>
            </div>

            <article className="ryb__details--text-only">
                <b><i>Други цветови схеми</i></b> се прилагат в изработването на карти и диаграми, 
                в илюстрирането и анализа на данни. Цветовете се използват като графичен инструмент 
                според психологическия им ефект, контраст и информация, интуитивно приемани от човешкото око.
                Богатството и разнообразието от значения могат да визуализират различни количествени вариации и диапазони.
            </article>
            <div className="ryb__details--others">
                <img src="/images/schemes/Sequential.jpg" alt="sequential" className="ryb__details--others-img" />
                <img src="/images/schemes/Divergent.jpg" alt="divergent" className="ryb__details--others-img" />
            </div>
            <article className="ryb__details--text-only">
                <b><i>Последователна схема</i></b> се използва предимно за подреждане на стойности от различнa величинa. 
                Обикновено се използва един цвят и неговите варианти.
                Светлите нюанси се възприемат интуитивно като ниски стойности, 
                а наситените и тъмни оттенъци - като високи.
                <br /> <b><i> Дивергентната (разделна) схема</i></b> използва две последователни схеми,
                свързани с общ цвят. Обикновено светлите цветове се събират в средата, 
                а тъмните се подреждат към двата края. Такава комбинация подчертава 
                екстремните стойности по периферията и средната критична стойност в центъра. 
                В случаите с общ междинен цвят схемите най-често са асиметрични.
            </article>
        </section>
    );
};

export default SchemesBG;