const WheelsBG = () => {

    return (
        <section id="schemeDetailsSection" className="ryb__details">
            <h5 className="ryb__info">Колело на цветовете</h5>

            <article><b><i>Цветното колело</i></b> е абстрактна илюстрация на цветовете. 
            Те са подредени по определен начин в диаграма във формата на кръг, която представя връзката между тях. 
            Повечето цветни колела са базирани на три основни цвята, три второстепенни 
            (образувани от смесването на равни количества от два основни) и 
            шест спомагателни цвята - комбинация от основни и второстепенни цветове. </article>

            <article> Различните цветови модели представят и описват цветовете в различни стойности и цветови пространства. 
                Разделението следва от несъответствието между възприемането на цветовете от човешкото око 
                и от начина на формиране на цветовете в изходните устройства 
                (монитори, екрани, дисплеи) и тяхното визуализиране. </article>
            
            <div className="ryb__details--wheels">
                <img src="/images/rgb.png" alt="rgb wheel" className="ryb__details--wheel-img" />
                <img src="/images/ryb_.png" alt="ryb wheel" className="ryb__details--wheel-img" />
                <img src="/images/cmy.png" alt="cmy wheel" className="ryb__details--wheel-img" />
            </div>
            
            <article > В адитивните модели цветовете се получават чрез смесване и добавяне на светлинни 
                потоци. <b><i>RGB моделът</i></b> (Red Green Blue) приема за основни цветове червеното, 
                зеленото и синьото, a жълто, циан и маджента са второстепенни (вторични). 
                При липса на трите основни цвята, резултатът е черно, а при смесването им, се получава бяла светлина. 
                RGB моделът е широко използван в дигиталните системи. </article>
            <article> При субстрактивните модели се отнема от спектъра на светлинния поток. Липсата на цвят означава бяло, 
                а от смесването на трите основни цвята се получава неутрално тъмно сиво до черно.
                Субстрактивните модели се използват при образуването на цветовете чрез смесване на цветни субстанции - бои и пигменти. 
                Основните (първични) цветове са червено, жълто и синьо (Red Yellow Blue). Вторичните - оранжево, зелено 
                и лилаво - се получават от смесване на основните. <b><i>RYB колелото</i></b> се базира 
                на традиционната теория на цветовете и е широко използванo от артисти, художници и дизйнери 
                във всички сфери на изкуството, модата, интериора, ежедневието. RYB колелото е най-добрият вариант 
                за откриване на цветовете, които си подхождат, и за комбинирането им в цветови схеми. </article>
            <article> <b><i>CMY колелото</i></b> представя друг субстрактивен модел,
            в който циан, маджента и жълто се смесват до получаване на черно, но липсва начин за постигане на бяло. 
            CMY (CMYK) моделът се използва за цветен печат в полиграфията и фотографията. При необходимост от бяло, 
            при принтирането върху хартията не се нанася никакво мастило, боя или пигмент.</article>
            
        </section>
    );
};

export default WheelsBG;